import * as React from "react";

import { ActivePageManager } from "@maxxton/cms-mxts-api";
import { DynamicWidgetBaseProps } from "../../dynamicWidget.types";
import { MyEnvContainerContextProvider } from "./dynamicMyEnvContainer.context";
import { ReservationContainerCRPProps } from "../../reservation/container/reservationContainer.types";
import { WidgetOptions } from "./index";
import { injectPropIntoElementTrees } from "../../../resultsPanel/resultsPanelUtil";
import { isWidgetHidden } from "../../../../components/utils";
import { refreshSelectedMyEnvReservation } from "../../../../redux/actions/myEnvAction";
import { useDispatch } from "react-redux";

export interface DynamicMyEnvContainerProps extends DynamicWidgetBaseProps<WidgetOptions>, ReservationContainerCRPProps {
    childrenElements: Array<{ element: JSX.Element; options: any }>;
}

function DynamicMyEnvContainerComponent(props: DynamicMyEnvContainerProps) {
    const { options, childrenElements } = props;
    const dispatchAction = useDispatch();
    React.useEffect(() => {
        ActivePageManager.get()?.setPageCategory("myEnv");
        const onPageLoad = () => {
            // Make sure we refresh the selected reservation in case it changed at mxts side. Delay refreshing the selectedMyEnvReservation till after page load.
            dispatchAction(refreshSelectedMyEnvReservation(undefined, true));
        };
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, []);

    if (isWidgetHidden(options)) {
        return null;
    }
    return (
        <MyEnvContainerContextProvider>
            {injectPropIntoElementTrees(
                childrenElements.map((child, index) => React.cloneElement(child.element, { key: index, childOptions: child.options })),
                { isMyEnvWidget: true }
            )}
        </MyEnvContainerContextProvider>
    );
}

export const DynamicMyEnvContainerWidget = DynamicMyEnvContainerComponent;
