import * as React from "react";

import { PageWidgetSpec, ResultPanelWidgetSpec, isPageWidget, isTypeResultsWidget, reportWidgetRenderError } from "../../../widget";

import { AddOnsWidgetOptions } from "../../add-ons/AddOns.types";
import { DynamicMyEnvContainerChildOptions } from "./dynamicMyEnvContainer.types";
import { DynamicMyEnvContainerWidget } from "./dynamicMyEnvContainer";
import { FormSpec } from "../../../../form-specs/index";
import { WidgetGroup } from "../../../widget.enum";
import { getI18nLocaleObject } from "../../../../i18n/loader";
import { getWidgetListOptions } from "../../../../inputSpecs/widgetListAutocomplete";
import namespaceList from "../../../../i18n/namespaceList";

export interface WidgetOptions {}

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "reservation-container-widget",
    name: getI18nLocaleObject(namespaceList.dynamicMyEnvContainer, "dynamicMyEnvContainerWidgetName"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicMyEnvContainer, "dynamicMyEnvContainerWidgetsName"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [[]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [],
                },
            ],
        },
    ],
};

export const dynamicMyEnvContainerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "DynamicMyEnvContainerWidget",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.MY_ENVIRONMENT : 7,
    name: getI18nLocaleObject(namespaceList.dynamicMyEnvContainer, "dynamicMyEnvContainerWidgetName"),
    description: getI18nLocaleObject(namespaceList.dynamicMyEnvContainer, "dynamicMyEnvContainerWidgetDescription"),
    optionsForm: widgetOptionsForm,
    childOptions: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "tabOptions"),
                    properties: [
                        [
                            {
                                variable: "disableAddOns",
                                label: getI18nLocaleObject(namespaceList.widgetAddOns, "disableAddOns"),
                                type: "checkbox",
                                default: false,
                                visible: (options: AddOnsWidgetOptions) => "quickAddSimpleAddOns" in options,
                            },

                            {
                                variable: "disableCheckedInReservationAddOns",
                                label: getI18nLocaleObject(namespaceList.widgetAddOns, "disableCheckedInReservationAddOns"),
                                type: "checkbox",
                                default: false,
                                visible: (options: AddOnsWidgetOptions) => !!options.disableAddOns,
                            },
                            {
                                variable: "disableHistoricalReservationAddOns",
                                label: getI18nLocaleObject(namespaceList.widgetAddOns, "disableHistoricalReservationAddOns"),
                                type: "checkbox",
                                default: false,
                                visible: (options: AddOnsWidgetOptions) => !!options.disableAddOns,
                            },
                            {
                                variable: "disableReservationAddOnsBeforeXHours",
                                label: getI18nLocaleObject(namespaceList.widgetAddOns, "disableReservationAddOnsBeforeXHours"),
                                maximumNumberRange: 75,
                                type: "number",
                                visible: (options: AddOnsWidgetOptions) => !!options.disableAddOns,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.dynamicMyEnvContainer, "dynamicMyEnvContainer"),
                    properties: [
                        [
                            {
                                variable: "isEditable",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "isEditable"),
                            },
                            {
                                variable: "askForApprovalByEmail",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "askForApprovalByEmail"),
                            },
                            {
                                variable: "uploadImage",
                                type: "checkbox",
                                groupName: "uploadImageOptionsGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicContainer, "uploadImageOptionsTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicContainer, "uploadImageOptionsGroupDescription"),
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "uploadImage"),
                                visible: (options: DynamicMyEnvContainerChildOptions) => !!options.askForApprovalByEmail,
                            },
                            {
                                variable: "unitEditableFormWidgetList",
                                label: getI18nLocaleObject(namespaceList.admin, "unitEditableFormWidgetList"),
                                type: "multiselect",
                                groupName: "unitEditableFormWidgetGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "unitEditableFormWidgetTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.admin, "unitEditableFormWidgetDescription"),
                                async optionList({ initialItem }): Promise<any[]> {
                                    return getWidgetListOptions(initialItem);
                                },
                                visible: (item: DynamicMyEnvContainerChildOptions) => item.askForApprovalByEmail,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
    defaultOptions: (): WidgetOptions => ({}),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const childrenElements = await Promise.all(
            widget.children.map((child, index) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec) || isTypeResultsWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions)
                        .then((element) => ({ element, options: child.options }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={index} />, options: {} };
                        });
                }
                throw new TypeError("Expected child widgets to be page widgets");
            })
        );
        return <DynamicMyEnvContainerWidget options={widget.options} dynamicContainerOptions={dynamicContainerOptions} context={context} childrenElements={childrenElements} />;
    },
};

export const crpDynamicMyEnvContainerWidget: ResultPanelWidgetSpec<WidgetOptions> = {
    ...dynamicMyEnvContainerWidget,
    type: "resultsPanel",
    widgetGroup: WidgetGroup.DYNAMIC,
};
