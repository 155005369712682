import * as React from "react";

const MyEnvContainerContext = React.createContext<MyEnvContainerContextProps>({});

interface MyEnvContainerContextProps {
    isMyEnvWidget?: boolean;
}

export const useMyEnvContainerContext = () => React.useContext(MyEnvContainerContext);

export const MyEnvContainerContextProvider = (props: { children: React.ReactNode }) => {
    const { children } = props;
    return <MyEnvContainerContext.Provider value={{ isMyEnvWidget: true }}>{children}</MyEnvContainerContext.Provider>;
};

export const MyEnvContainerContextConsumer = MyEnvContainerContext.Consumer;
